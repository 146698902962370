import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER = `${API_URL}v3/broadcast/get_user`
export const GET_USERS = `${API_URL}wzcorp/agent/list`
export const CONNECT_USER = `${API_URL}wzcorp/agent/connect`
export const SUBMIT_OTP = `${API_URL}wzcorp/agent/submit_otp`
export const DISCONNECT_USER = `${API_URL}wzcorp/agent/disconnect`
export const UPDATE_NAME = `${API_URL}wzcorp/agent/rename`
export const ADD_CREDIT = `${API_URL}wzcorp/inject_credit`

export function getUser(phone: string) {
    return axios.post(GET_USER, { phone });
}

export function getUsers(accessToken: string) {
    return axios.post(GET_USERS, {},
        {
            headers: {
                'x-auth-token': accessToken
            },
        }
    );
}

export function connect(accessToken: string, phone: string, source?: string) {
    return axios.post(CONNECT_USER, { phone, source },
        {
            headers: {
                'x-auth-token': accessToken
            },
        }
    );
}

export function submit_otp(accessToken: string, phone: string, otp: string) {
    return axios.post(SUBMIT_OTP, { phone, otp },
        {
            headers: {
                'x-auth-token': accessToken
            },
        }
    );
}

export function disconnect(accessToken: string, phone: string) {
    return axios.post(DISCONNECT_USER, { phone },
        {
            headers: {
                'x-auth-token': accessToken
            },
        }
    );
}

export function addCredit(amount: number, userWooblazz_phone: string, accessToken: string) {
    return axios.post(ADD_CREDIT,
        {
            amount,
            userWooblazz_phone
        },
        {
            headers: {
                'x-auth-token': accessToken
            }
        }
    )
}

export function updateName(accessToken: string, phone: string, name: string) {
    return axios.post(UPDATE_NAME, { phone, name },
        {
            headers: {
                'x-auth-token': accessToken
            },
        }
    );
}