import clsx from 'clsx'
import React, { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search } from '../../../partials'
import { useLayout } from '../../core'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {shallowEqual, useSelector} from 'react-redux'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const { config } = useLayout()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <div className="d-flex align-items-center cursor-pointer btn btn-muted btn-active-light btn-active-color-primary h-30px h-md-40px px-3" data-kt-menu-trigger='click' data-kt-menu-attach='parent' data-kt-menu-placement='bottom-end' data-kt-menu-flip='bottom'>
        <span className="menu-icon">
            <i className="fas fa-user fs-3"></i>
        </span>
        <div className="fw-bolder d-flex align-items-center fs-5 text-dark ms-2 menu-title" id="txtNameHeader">{user.data.user_name}</div>
      </div>
      <HeaderUserMenu />
    </>
  )
}

export { Topbar }
