import { FC, useEffect, useRef, useState } from "react"
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { ConnectedProps, connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { UserModel } from "../app/modules/auth/models/UserModel";
import { RootState } from "../setup";
import { Modal } from "bootstrap";
import { getUserByToken, updateProfile } from "../app/modules/auth/redux/AuthCRUD";
import * as auth from '../app/modules/auth/redux/AuthRedux'
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const CompleteProfileModal: FC<PropsFromRedux> = (props) => {

    const reduxUser: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );
    const dispatch = useDispatch()

    const closeModalRef = useRef<HTMLButtonElement | null>(null)
    const modalRef = useRef<HTMLDivElement | null>(null)
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            address: '',
            phone: '',
            toc: false
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Nama Perusahaan harus diisi'),
            address: Yup.string().required('Alamat Perusahaan harus diisi'),
            phone: Yup.string().required('Nomor Perusahan harus diisi'),
            toc: Yup.boolean().oneOf([true], 'Anda harus menyetujui ketentuan layanan')
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true);
            setModalBtnLoading(true);
            try {
                const response = await updateProfile(values.name, values.phone, values.address, accessToken);
                if (response.data.success) {
                    setSubmitting(false);
                    setModalBtnLoading(false);
                    const { data: user } = await getUserByToken(accessToken as string)
                    dispatch(props.fulfillUser(user))
                    closeModalRef.current?.click();
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil',
                        heightAuto: false,
                        text: 'Profil berhasil disimpan!'
                    })
                }
            } catch (error) {
                console.error(error);
            }
        }
    });

    useEffect(() => {
        if (!showModal && !reduxUser.data.user_name) {
            const modalElement = document.getElementById(`complete-profile-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [reduxUser.data.user_name]);

    return (
        <div className="modal fade" tabIndex={-1} id="complete-profile-modal" ref={modalRef} data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Lengkapi Profil</h5>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body fs-6">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>Nama Perusahaan</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder='Isi nama perusahaan'
                                    {...formik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                        {
                                            'is-valid': formik.touched.name && !formik.errors.name,
                                        }
                                    )}
                                    type='text'
                                    name='name'
                                    autoComplete='off'
                                />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container mb-5'>
                                    <span role='alert' className="text-danger">{formik.errors.name}</span>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>Alamat Perusahaan</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder='Isi alamat perusahaan'
                                    {...formik.getFieldProps('address')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.address && formik.errors.address },
                                        {
                                            'is-valid': formik.touched.address && !formik.errors.address,
                                        }
                                    )}
                                    type='text'
                                    name='address'
                                    autoComplete='off'
                                />
                            </div>
                            {formik.touched.address && formik.errors.address && (
                                <div className='fv-plugins-message-container mb-5'>
                                    <span role='alert' className="text-danger">{formik.errors.address}</span>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>Nomor Perusahaan</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder='Isi nomor perusahaan'
                                    {...formik.getFieldProps('phone')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.phone && formik.errors.phone },
                                        {
                                            'is-valid': formik.touched.phone && !formik.errors.phone,
                                        }
                                    )}
                                    type='phone'
                                    name='phone'
                                    autoComplete='off'
                                    onInput={(event) => {
                                        // Replace non-digits
                                        event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, '');

                                        // Remove leading zeros
                                        while (event.currentTarget.value.startsWith("0")) {
                                            event.currentTarget.value = event.currentTarget.value.substring(1);
                                        }
                                    }}
                                />
                            </div>
                            {formik.touched.phone && formik.errors.phone && (
                                <div className='fv-plugins-message-container mb-5'>
                                    <span role='alert' className="text-danger">{formik.errors.phone}</span>
                                </div>
                            )}
                            <div className="input-group my-3">
                                <div className="mb-10">
                                    <div className="form-check form-check-custom form-check-solid d-block">
                                        <input className="form-check-input" type="checkbox" {...formik.getFieldProps('toc')} id="flexCheckDefault" />
                                        <label className='form-label ms-1 fs-6 text-dark align-self-center'>
                                            Dengan ini, saya menyetujui&nbsp;
                                            <Link to='/toc' target="_blank">Ketentuan Layanan Looyal</Link>
                                        </label>
                                    </div>
                                    {formik.touched.toc && formik.errors.toc && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert' className="text-danger">{formik.errors.toc}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                Batal
                            </button>
                            <button type="submit" className="btn btn-primary" data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">Simpan</span>
                                <span className="indicator-progress">Harap tunggu...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connector(CompleteProfileModal)