import { useEffect, useState } from 'react';
import { PurchaseHistory, usePurchaseHistoryStore } from '../../../stores/PurchaseHistoryStore';
import { GenerateQris } from '../../../components/Purchase/GenerateQris';
import { getPurchaseHistory } from '../../modules/purchaseHistory/PurchaseHistoryCRUD';
import { customNumberFormat } from '../../../function/general';
import moment from "moment";
import 'moment/locale/id';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../setup';

const PurchasesHistory: React.FC = () => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );

    // const phone = "6283847070417"
    const { purchasesHistory, setPurchaseHistory } = usePurchaseHistoryStore()

    const [actionRow, setActionRow] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterText, setFilterText] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const filteredData = purchasesHistory
        .filter(item => {
            // If there's a filterText, filter based on it.
            if (filterText) {
                return Object.values(item).some(val =>
                    String(val).toLowerCase().includes(filterText.toLowerCase())
                );
            }
            // If there's no filterText, return all items.
            return true;
        })

    const columns: TableColumn<PurchaseHistory>[] = [
        {
            name: 'No',
            width: '10%',
            cell: (row, rowIndex) => {
                const actualIndex = (currentPage - 1) * rowsPerPage + rowIndex + 1;
                return <span>{actualIndex}</span>;
            },
            center: true,
            sortable: true
        },
        {
            name: 'Tanggal Pembelian',
            selector: row => row.date_formatted,
            sortable: true
        },
        {
            name: 'Tipe',
            selector: row => row.type,
            cell: (row) => {
                if (row.type === 4) return 'Kuota Corporate'
            },
            sortable: true
        },
        {
            name: 'Value',
            selector: row => row.value,
            cell: (row) => {
                return customNumberFormat(row.value) + " Token"
            },
            sortable: true
        },
        {
            name: 'Harga',
            selector: row => row.price,
            cell: (row) => {
                return "Rp." + customNumberFormat(row.price)
            },
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: (row) => {
                if (row.status === 1) return <span className="badge badge-light-success fs-base">Sukses</span>
                else if (row.status === 2) {
                    return <span className="badge badge-light-warning fs-base">Menunggu</span>;
                } else if (row.status === 3) {
                    return <span className="badge badge-light-danger fs-base">Kadaluarsa</span>;
                }
            },
            sortable: true
        },
        {
            name: 'Aksi',
            center: true,
            width: '10%',
            cell: (row) => {
                if (row.status === 2) {
                    return (
                        <>
                            <div className="dropdown dropend">
                                <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-three-dots-vertical fs-3"></i>
                                    <span className="caret"></span>
                                </button>
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4 dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <div className="menu-item px-3">
                                        <span className="menu-link" data-bs-toggle="modal" data-bs-target={`#generate-qris-modal-${row.hash}`} role="button" id="optionPay">
                                            <span className="fas fa-regular fa-money-bill-wave fs-3 menu-icon"></span>
                                            <span className="menu-title">Bayar</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <GenerateQris key={row.code} hash={row.hash} price={row.price} date={row.date} />
                        </>
                    )
                }
            },
            omit: !actionRow
        },
    ]

    useEffect(() => {
        const fetchPurchasesHistory = async () => {
            try {
                const response = await getPurchaseHistory(accessToken);
                if (response.data.success) {
                    const data = response.data.data.filter((item: any) => item.type !== 0);
                    data.forEach((purchaseHistory: PurchaseHistory) => {
                        purchaseHistory.date_formatted = moment(purchaseHistory.date).format('DD MMMM YYYY HH:mm')
                    })
                    // find if there are any status 2 in the data
                    const status2 = data.find((item: any) => item.status === 2);
                    if (status2) setActionRow(true);
                    setPurchaseHistory(data);
                    setIsLoading(false);
                } else {
                    throw new Error("Failed to fetch purchasesHistory");
                }
            } catch (error) {
                throw error;
            }
        }
        fetchPurchasesHistory();
    }, []);
    return (
        <div>
            <div className="d-flex justify-content-between">
                <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                    <li className="breadcrumb-item text-dark">Riwayat Pembelian</li>
                </ol>
            </div>

            <div className="card">
                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1 skeleton-button" id="divSearch">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                </svg>
                            </span>
                            <input
                                type="text"
                                className="form-control form-control-solid w-250px ps-15 skeleton"
                                placeholder="Cari"
                                id="inputSearch"
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    {
                        isLoading &&
                        <div className="loading-overlay">
                            <div className="loader"></div>
                        </div>
                    }
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        onChangePage={(page) => setCurrentPage(page)}
                        onChangeRowsPerPage={(currentRowsPerPage: number) => setRowsPerPage(currentRowsPerPage)}
                    />
                </div>
            </div>
        </div>
    )
}

export { PurchasesHistory }