import { useState, useRef, useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import QRCode from "qrcode";
import { useTimer } from "../../../hooks/Timer";
import { secondsToTimeFormat } from "../../../function/general";
import { checkQris, showQris } from "../../../app/modules/qris/QrisCRUD";
import Swal from "sweetalert2";
import { usePurchaseHistoryStore } from "../../../stores/PurchaseHistoryStore";
import { useCorporateUserStore } from "../../../stores/CorporateUserStore";
interface GenerateQrisProps {
    hash: string;
    price?: number;
    date?: string;
}

const GenerateQris: React.FC<GenerateQrisProps> = ({ hash, price, date }) => {

    let durationInSeconds = 3600;

    if (date) {
        const targetDate = new Date(date);

        // Add 1 hour to the Date object
        targetDate.setHours(targetDate.getHours() + 1);
        // Calculate the duration in seconds from the current time to the target time
        const now = Date.now();
        const targetTime = targetDate.getTime();
        durationInSeconds = Math.floor((targetTime - now) / 1000);
    }

    const { countDown } = useTimer(durationInSeconds);

    const [qr, setQr] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [intervalId, setIntervalId] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const { purchasesHistory, setPurchaseHistory } = usePurchaseHistoryStore()

    const canvasRef = useRef<HTMLCanvasElement | null>(null)

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleModalShow = () => setIsVisible(true);
        const handleModalHide = () => setIsVisible(false);

        const modalElement = document.getElementById(`generate-qris-modal-${hash}`);
        modalElement?.addEventListener('shown.bs.modal', handleModalShow);
        modalElement?.addEventListener('hidden.bs.modal', handleModalHide);

        return () => {
            modalElement?.removeEventListener('shown.bs.modal', handleModalShow);
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHide);
        };
    }, [hash]);

    useEffect(() => {
        if (countDown === 0) {
            setPurchaseHistory(purchasesHistory.map((purchaseHistory) => {
                if (purchaseHistory.hash === hash) {
                    purchaseHistory.status = 3;
                }
                return purchaseHistory;
            }))
        }

        if (isVisible) {
            if (countDown === 0) {
                const button = document.getElementById(`btnCancelPay-${hash}`);
                button?.click();
                setPurchaseHistory(purchasesHistory.map((purchaseHistory) => {
                    if (purchaseHistory.hash === hash) {
                        purchaseHistory.status = 3;
                    }
                    return purchaseHistory;
                }))
                Swal.fire({
                    title: 'QRIS Expired!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    heightAuto: false,
                })
            }
        }
    })

    useEffect(() => {
        if (isVisible) {
            const getQris = async () => {
                setIsLoading(true);
                try {
                    const qrisResponse = await showQris(hash, price!, '');
                    if (qrisResponse.data.success) {
                        setQr(qrisResponse.data.data);
                    }
                } catch (error) {
                    console.error("Error fetching QR code:", error);
                } finally {
                    setIsLoading(false); // End loading
                }
            };
            getQris();
        }
    }, [isVisible, hash, price]);

    useEffect(() => {
        if (isVisible && qr) {
            const id = setInterval(async () => {
                try {
                    const response = await checkQris(hash, price!);

                    if (response.data.data === "1") {
                        clearInterval(id);
                        setIntervalId(null);
                        // click the modal close button
                        const button = document.getElementById(`btnCancelPay-${hash}`);
                        button?.click();
                        setPurchaseHistory(purchasesHistory.map((purchaseHistory) => {
                            if (purchaseHistory.hash === hash) {
                                purchaseHistory.status = 1;
                            }
                            return purchaseHistory;
                        }))
                        Swal.fire({
                            title: 'Pembayaran Berhasil!',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            customClass: {
                                confirmButton: 'btn btn-primary'
                            },
                            heightAuto: false,
                        })
                    }
                } catch (err) {
                    console.error("Error checking QRIS:", err);
                    setError("Error checking QRIS.");
                }
            }, 3000) as unknown as number;

            setIntervalId(id);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isVisible, qr, hash, price]);

    const manualCheckQris = async () => {
        setIsLoading(true);
        try {
            const response = await checkQris(hash, price!);

            if (response.data.data === "1") {
                // click the modal close button
                const button = document.getElementById(`btnCancelPay-${hash}`);
                button?.click();
                setPurchaseHistory(purchasesHistory.map((purchaseHistory) => {
                    if (purchaseHistory.hash === hash) {
                        purchaseHistory.status = 1;
                    }
                    return purchaseHistory;
                }))
                Swal.fire({
                    title: 'Pembayaran Berhasil!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    heightAuto: false,
                })
            }
        } catch (err) {
            console.error("Error checking QRIS:", err);
            setError("Error checking QRIS.");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (canvasRef.current) {
            QRCode.toCanvas(canvasRef.current, qr!, (err) => {
                if (err) console.error('Error generating QRCode', err);
            });
        }
    }, [qr]);
    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`generate-qris-modal-${hash}`} data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ padding: "2rem" }}>
                        <div className="row" style={{ padding: "0px 20px" }}>
                            <div className="col-6 text-start">
                                <img src={toAbsoluteUrl("/media/logos/qris.png")} alt="QRIS Logo" height="50px" />
                            </div>
                            <div className="col-6 text-end">
                                <img src={toAbsoluteUrl("/media/logos/gpn.png")} alt="GPN Logo" height="50px" />
                            </div>

                        </div>
                        <div className="modal-body" style={{ fontSize: "15px", textAlign: "center" }}>
                            {
                                isLoading &&
                                <div className="loading-overlay">
                                    <div className="loader"></div>
                                </div>
                            }
                            <canvas ref={canvasRef}>
                            </canvas>
                            <div className="text-center fw-bolder fs-5 mt-3">
                                Waktu pembayaran :&nbsp;
                                <span
                                    className="link-primary fw-bolder fs-5 me-1"
                                    id={`qris-countdown-${hash}`}
                                    tabIndex={countDown ? -1 : 0}
                                >
                                    <span className="text-muted me-1">
                                        <span className="text-primary fw-bolder fs-5">
                                            {countDown <= 0 ? "QRIS Expired" : secondsToTimeFormat(countDown)}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="row text-center mt-2">
                            <div className="col">
                                <span style={{ fontSize: "14px" }}>Powered by <b>Looyal Indonesia</b></span>
                            </div>
                        </div>
                        <div className="row text-center mt-5">
                            <div className="col" style={{ justifyContent: "space-between", display: "flex" }}>
                                <button
                                    className="btn btn-secondary"
                                    style={{ width: "70%" }}
                                    id={`btnCancelPay-${hash}`}
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setIsVisible(false);
                                        if (intervalId) clearInterval(intervalId);
                                    }}
                                >
                                    Tutup
                                </button>
                                <button
                                    className="btn btn-primary"
                                    style={{ width: "28%" }}
                                    id="btnRefreshPay"
                                    onClick={
                                        () => {
                                            manualCheckQris();
                                        }}
                                >
                                    Refresh
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { GenerateQris }