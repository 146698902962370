/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen008.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Agen</span>
        </div>
      </div>
      <AsideMenuItem
        to='/users'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Agen'
        fontIcon='bi-layers'
      />
      {
        (window.location.host !== 'www.otpwa.com' && window.location.host !== 'localhost:3011') &&
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Paket</span>
            </div>
          </div>
          {
            user.data.user_username !== "memberid" &&
            <AsideMenuItem
              to='/purchase'
              icon='/media/icons/duotune/ecommerce/ecm001.svg'
              title='Pembelian'
              fontIcon='bi-layers'
            />
          }
          <AsideMenuItem
            to='/history-purchase'
            icon='/media/icons/duotune/general/gen014.svg'
            title='Riwayat Pembelian'
            fontIcon='bi-layers'
          />
        </>

      }
    </>
  )
}
