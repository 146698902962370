import { useFormik } from "formik"
import clsx from "clsx";
import * as Yup from 'yup';
import { useState, useRef, useEffect } from "react";
import { KTSVG } from "../../../_metronic/helpers"
import { connect } from "../../../app/modules/users/UserCRUD";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../setup";
import { useNotificationStore } from "../../../stores/NotificationStore";
import { useUserStore } from "../../../stores/UserStore";
import { Modal } from "bootstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-international-phone/style.css';
import { UserModel } from "../../../app/modules/auth/models/UserModel";

interface ConnectProps {
    disabled?: boolean;
    connectedAgents: number;
}

const Connect: React.FC<ConnectProps> = ({ disabled, connectedAgents }) => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );

    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    const { users, setUsers } = useUserStore()
    const { setNotification } = useNotificationStore()
    const modalRef = useRef<HTMLDivElement | null>(null);

    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const closeConnectBtnRef = useRef<HTMLButtonElement>(null);
    // const [prevCountryCode, setPrevCountryCode] = useState("62");

    const handleCloseClick = () => {
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            phone: ''
        },
        validationSchema: Yup.object().shape({
            phone: Yup.number()
                .typeError('Telepon harus angka')
                .required('Telepon harus diisi'),
        }),
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true);
            setSubmitting(true);
            const phone = values.phone
            // check if phone is already connected
            const isAlreadyConnected = users.find(user => user.user_phone === phone);
            if (!isAlreadyConnected) {
                setTimeout(async () => {
                    try {
                        var response
                        if (window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011') {
                            response = await connect(accessToken, phone, "otpwa");
                        } else {
                            response = await connect(accessToken, phone);
                        }

                        if (response.data.success) {
                            setUsers([response.data.data, ...users]);
                            closeConnectBtnRef.current?.click();
                            setNotification({
                                title: "OTP Terkirim",
                                message: `OTP terkirim ke ${phone}`
                            });
                            formik.resetForm();

                            const userCode = response.data.data.corporate_user_code;
                            const modalElement = document.getElementById(`otp-modal-${userCode}`);
                            if (modalElement) {
                                const modalInstance = new Modal(modalElement);
                                modalInstance.show();
                            }
                        } else {
                            throw new Error("Gagal Menambah Agen");
                        }
                    } catch (error) {
                        setStatus('Gagal menambahkan Agen. Harap cek apakah nomor sudah sesuai format.');
                    } finally {
                        setModalBtnLoading(false);
                    }
                }, 1000);
            } else {
                setTimeout(() => {
                    setStatus('User already connected');
                    setModalBtnLoading(false);
                }, 1000);
            }
            setSubmitting(false);
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.setFieldValue('phone', '62');
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const percentageOfConnectedAgents = (connectedAgents / user.data.user_limit_agent) * 100;

    const [progressBarWidth, setProgressBarWidth] = useState<string>('');


    const bgProgressBar = percentageOfConnectedAgents <= 40 ? 'bg-light-success' : (connectedAgents === user.data.user_limit_agent ? 'bg-light-danger' : 'bg-light-warning');
    const progressBarColor = percentageOfConnectedAgents <= 40 ? 'bg-success' : (connectedAgents === user.data.user_limit_agent ? 'bg-danger' : 'bg-warning');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 786) {
                setProgressBarWidth('w-125px');
            } else {
                setProgressBarWidth('w-200px');
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div className="d-flex justify-content-end align-items-center gap-4">
                <div className="border border-1 border-dark position-relative">
                    <div className={`h-35px ${progressBarWidth} ${bgProgressBar}`}>
                        <div
                            className={`${progressBarColor} progress-bar-striped h-35px`}
                            role="progressbar"
                            style={{ width: `${percentageOfConnectedAgents > 100 ? '100' : percentageOfConnectedAgents}%` }}
                        >
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center text-black fw-bolder fs-4 position-absolute top-50 start-50 translate-middle w-100">
                        <div className="d-flex justify-content-center" style={{ flex: 1 }}>
                            <i className="fas fa-users text-black me-2 align-self-center"></i>{connectedAgents}/{user.data.user_limit_agent}
                        </div>
                        <a href={`https://wa.me/6282330779799?text=ORDER%20TAMBAH%20LIMIT%20AGEN%20WOBBLAZZ%20CORPORATE%0Anama%20corporate:%20${user.data.user_name}`} target="_blank" rel="noreferrer">
                            <KTSVG path="/media/icons/duotune/general/gen035.svg" className="svg-icon-primary svg-icon-2hx" />
                        </a>
                    </div>
                </div>
                <button type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#connect-modal"
                    disabled={disabled || connectedAgents === user.data.user_limit_agent}
                >
                    Tambah Agen
                </button>
            </div>
            <div className="modal fade" tabIndex={-1} id="connect-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Tambah Agen</h4>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body fs-6">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>Telepon</label>
                                <div className="input-group mb-3">
                                    <PhoneInput
                                        countryCodeEditable={false}
                                        country="id"
                                        value={formik.values.phone}
                                        onChange={(value, country: any) => {
                                            // if (country.dialCode !== prevCountryCode) {
                                            //     setPrevCountryCode(country.dialCode);
                                            //     formik.setFieldValue('phone', country.dialCode);
                                            // } else {
                                            formik.setFieldValue('phone', value);
                                            // }
                                        }}
                                        enableSearch={true}
                                        inputStyle={{ width: "100%" }}
                                        dropdownClass="z-index-3"
                                    />
                                </div>
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{formik.errors.phone}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeConnectBtnRef}
                                    onClick={handleCloseClick}
                                >
                                    Batal
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">Tambah</span>
                                    <span className="indicator-progress">Harap tunggu...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Connect }