import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const GET_PACKAGES = `${API_URL}v3/broadcast_package/select`
export const BUY_PACKAGE = `${API_URL}wzcorp/buy_package`
export const SHOW_QRIS = `${API_URL}v3/qris/insert`

export function getPackages(accessToken: string) {
    return axios.post(GET_PACKAGES, {},
        {
            headers: {
                'x-auth-token': accessToken
            },
        }
    );
}

export function buyPackage(accessToken: string, code: number) {
    return axios.post(BUY_PACKAGE,
        {
            package: code
        },
        {
            headers: {
                'x-auth-token': accessToken
            },
        }
    );
}