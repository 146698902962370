import { FC, useState } from "react";
import ReactJson from "react-json-view";
import { ResponseExample } from "../../interfaces/ResponseExample";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface CodeProps {
    responseExample?: ResponseExample
    responseExampleSuccess?: ResponseExample
    responseExampleAlready?: ResponseExample
    python: string
    javascript: string
    php: string
    bash: string
    type: string
}

const Code: FC<CodeProps> = ({ responseExample, responseExampleSuccess, responseExampleAlready, python, javascript, php, bash, type }) => {
    const [codeBlock, setCodeBlock] = useState(python);

    const handleSendCodeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;
        switch (selectedOption) {
            case 'JavaScript':
                setCodeBlock(javascript);
                break;
            case 'PHP':
                setCodeBlock(php);
                break;
            case 'cURL':
                setCodeBlock(bash);
                break;
            default:
                setCodeBlock(python);
                break;
        }
    };

    return (
        <div style={{ paddingTop: "5rem" }}>
            {
                responseExample &&
                <>
                    <h1 className="fs-4">Contoh response: </h1>
                    <ReactJson src={responseExample} theme={"google"} />
                </>
            }

            {
                responseExampleAlready &&
                <>
                    <h1 className="fs-4">Contoh response ready: </h1>
                    <ReactJson src={responseExampleAlready} theme={"google"} />
                </>
            }

            {
                responseExampleSuccess &&
                <>
                    <h1 className="fs-4">Contoh response success: </h1>
                    <ReactJson src={responseExampleSuccess} theme={"google"} />
                </>
            }

            {
                type == "retrieve" ?
                    <h1 className="fs-4">Contoh request: </h1>
                    :
                    <>
                        <h1 className="fs-4">Contoh penggunaan: </h1>
                        <select name="code-language" id="" className="text-black mb-5" onChange={handleSendCodeChange}>
                            <option value="python">Python</option>
                            <option value="JavaScript">JavaScript</option>
                            <option value="PHP">PHP</option>
                            <option value="cURL">cURL</option>
                        </select>
                    </>
            }

            <SyntaxHighlighter style={atomOneDark}>
                {codeBlock}
            </SyntaxHighlighter>
        </div>
    )
}

export { Code }