export const base64encrypt = (text: string) => {
    return btoa(unescape(encodeURIComponent(text)));
};

export const encryptRot13 = (str: string) => {
    return str.replace(/[a-zA-Z]/g, function (c) {
        let base = c <= "Z" ? "A".charCodeAt(0) : "a".charCodeAt(0);
        return String.fromCharCode(base + ((c.charCodeAt(0) - base + 13) % 26));
    });
};
