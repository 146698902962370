import { KTSVG } from "../../../../_metronic/helpers"
import { User } from "../../../../interfaces/User";
import { useState, useRef, useEffect } from "react";
import { disconnect, getUsers, updateName } from "../../../../app/modules/users/UserCRUD";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../../setup";
import { useUserStore } from "../../../../stores/UserStore";
import { useNotificationStore } from "../../../../stores/NotificationStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";

interface DisconnectProps {
    user: User
}

const UpdateName: React.FC<DisconnectProps> = ({ user }) => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeBtnRef = useRef<HTMLButtonElement | null>(null);
    const { setNotification } = useNotificationStore()
    const { users, setUsers } = useUserStore()

    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            name: user.user_name
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Nama harus diisi'),
        }),
        enableReinitialize: true,
        onSubmit: async(values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true);
            setSubmitting(true);

            try {
                const response = await updateName(
                    accessToken,
                    user.user_phone,
                    values.name,
                )
                if (response.data.success) {
                    setNotification({
                        title: "Agen Diubah",
                        message: `Agen dengan telepon ${user.user_phone} berhasil diubah namanya`
                    });
                    closeBtnRef.current?.click();
                    setUsers(users.map(data => {
                        if (data.user_phone === user.user_phone) {
                            return {
                                ...data,
                                user_name: values.name
                            }
                        } else {
                            return data
                        }
                    }));
                }
            } catch (error: any) {
                setNotification({
                    title: "Error",
                    message: error.response.data.message
                });
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm();
        };

        const handleModalShow = () => {
            formik.setFieldValue('name', user.user_name);
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);
        modalElement?.addEventListener('show.bs.modal', handleModalShow);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
            modalElement?.removeEventListener('show.bs.modal', handleModalShow);
        };
    }, [formik, modalRef]);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`update-name-modal-${user.corporate_user_code}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Ubah Nama Agen</h4>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body fs-6">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>Nama</label>
                                <div className="input-group mb-3">
                                    <input
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>{formik.errors.name}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeBtnRef}
                                >
                                    Batal
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">Ubah</span>
                                    <span className="indicator-progress">Harap tunggu...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { UpdateName }