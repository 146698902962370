import { create } from 'zustand';
import { User } from '../interfaces/User';
import { persist, createJSONStorage } from 'zustand/middleware';

type UserStoreState = {
    users: User[];
    setUsers: (users: User[]) => void;
};

export const useUserStore = create<UserStoreState>()(
    persist(
        (set) => ({
            users: [],
            setUsers: (users) => set({ users }),
        }),
        {
            name: 'users-storage', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage),
        }
    )
);