/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-0'>
        {/* <a href='#' className='mb-2'>
          <img alt='Logo' id='logo-auth' src={logoAuth} className='h-125px' />
        </a> */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto' style={{ width: "100%" }}>
          <Outlet />
        </div>
      </div>

      {
        (window.location.host !== 'www.otpwa.com' && window.location.host !== 'localhost:3011') &&
        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <span className='text-muted px-2'>
              PT. Tunas Kreasi Digital
            </span>
          </div>
        </div>
      }
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
