import { KTSVG } from "../../../../_metronic/helpers"
import { User } from "../../../../interfaces/User";
import { useState, useRef, useEffect } from "react";
import { disconnect, getUsers, updateName } from "../../../../app/modules/users/UserCRUD";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../../setup";
import { useUserStore } from "../../../../stores/UserStore";
import { useNotificationStore } from "../../../../stores/NotificationStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { checkSession } from "../../../../app/modules/whatsapp/WhatsAppCRUD";

interface DisconnectProps {
    user: User
    setIsSession: (value: boolean) => void
}

const ConnectWhatsapp: React.FC<DisconnectProps> = ({ user, setIsSession }) => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );
    const modalRef = useRef<HTMLDivElement | null>(null);

    const fetchCheckSession = async () => {
        try {
            const response = await checkSession(user.user_phone);
            setIsSession(response.data.status);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            fetchCheckSession()
        };

        const handleModalShow = async () => {
            
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);
        modalElement?.addEventListener('show.bs.modal', handleModalShow);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
            modalElement?.removeEventListener('show.bs.modal', handleModalShow);
        };
    }, [modalRef]);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`connect-whatsapp-modal-${user.corporate_user_code}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Scan Qrcode</h4>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body" style={{ textAlign: "center" }}>
                            <iframe src={`https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'qr.otpwa.com' : 'wa.looyal.id'}/scan/${user.user_phone}`} title="scan-qr" height={'300px'}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ConnectWhatsapp }