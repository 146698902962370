import { Purchase } from "../../../app/pages/purchase";
import { customNumberFormat } from "../../../function/general";
import { PurchaseBuy } from "../PurchaseBuy";

interface PurchaseItemProps {
    purchase: Purchase;
}

const PurchaseItem: React.FC<PurchaseItemProps> = ({ purchase }) => {
    return (
        <div className="col-xl-4">
            <div className="d-flex h-100 align-items-center">
                <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                    <div className="mb-7 text-center">
                        <h3 className="text-dark mb-5 fw-boldest">{purchase.name}</h3>
                        <div className="text-center">
                            <span className="mb-2 fs-1 text-primary">Rp. </span>
                            <span className="fs-1 fw-bolder text-primary">{customNumberFormat(purchase.price)}</span>
                        </div>
                    </div>
                    <div className="w-100 mb-10">
                        <div className="d-flex align-items-center mb-5">
                            <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">{customNumberFormat(purchase.value)} Token</span>
                            <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                    <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-5">
                            <span className="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">Masa Aktif {customNumberFormat(purchase.day)} Hari</span>
                            <span className="svg-icon svg-icon-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                    <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="text-center">
                        <PurchaseBuy purchase={purchase} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export { PurchaseItem }