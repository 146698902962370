import { KTSVG } from "../../../../_metronic/helpers"
import { User } from "../../../../interfaces/User";
import { useState, useRef } from "react";
import { disconnect, getUsers } from "../../../../app/modules/users/UserCRUD";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../../setup";
import { useUserStore } from "../../../../stores/UserStore";
import { useNotificationStore } from "../../../../stores/NotificationStore";

interface DisconnectProps {
    user: User
}

const Disconnect: React.FC<DisconnectProps> = ({ user }) => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );
    const { users, setUsers } = useUserStore()
    const { setNotification } = useNotificationStore()

    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const closeDisconnectBtnRef = useRef<HTMLButtonElement | null>(null);

    const disconnectUser = async (phone: string) => {
        setModalBtnLoading(true);
        try {
            const response = await disconnect(accessToken, phone);
            if (response.data.success) {
                setTimeout(() => {
                    setModalBtnLoading(false);
                    closeDisconnectBtnRef.current?.click();
                    setUsers(users.filter(user => user.user_phone !== phone));
                    setNotification({
                        title: "Agen Terhapus",
                        message: `Agen dengan telepon ${phone} berhasil dihapus`
                    });
                }, 1000);
            } else {
                throw new Error("Gagal menghapus agen");
            }
        } catch (error) {
            throw error;
        }
    }
    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`disconnect-modal-${user.corporate_user_code}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Hapus Agen</h4>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p className="fs-6">Apakah anda yakin ingin menghapus agen <br /> <strong>{user.user_name} - {user.user_phone}</strong> ?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                ref={closeDisconnectBtnRef}
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Batal
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? "disabled" : ""}`}
                                onClick={() => disconnectUser(user.user_phone)}
                                data-kt-indicator={modalBtnLoading ? 'on' : 'off'}
                            >
                                <span className="indicator-label">Hapus</span>
                                <span className="indicator-progress">Harap tunggu...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Disconnect }