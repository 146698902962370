import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { changePassword } from "../../app/modules/auth/redux/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../setup";
import Swal from "sweetalert2";

const ChangePassword: FC = () => {
    const accessToken = useSelector<RootState, string>(
        ({ auth }) => auth.accessToken as string,
        shallowEqual
    );
    const modalRef = useRef<HTMLDivElement | null>(null);

    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const closeChangePasswordBtnRef = useRef<HTMLButtonElement>(null);

    const handleCloseClick = () => {
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object().shape({
            oldPassword: Yup.string().required('Password lama harus diisi').min(6, 'Password lama minimal 6 karakter'),
            password: Yup.string().required('Password baru harus diisi').min(6, 'Password baru minimal 6 karakter'),
            confirmPassword: Yup.string().required('Konfirmasi password harus diisi').oneOf([Yup.ref('password'), null], 'Password tidak sama')
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true);
            setSubmitting(true);
            try {
                const response = await changePassword(values.oldPassword, values.password, accessToken);
                if (response.data.success) {
                    Swal.fire({
                        title: "Berhasil!",
                        text: "Password telah berhasil diubah!",
                        icon: "success",
                        buttonsStyling: !1,
                        confirmButtonText: "OK",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        reverseButtons: true,
                        heightAuto: false,
                    })
                    closeChangePasswordBtnRef.current?.click();
                }
            } catch (error) {
                setStatus('Password Lama Salah');
            } finally {
                setSubmitting(false);
                setModalBtnLoading(false);
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm();
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);
    return (
        <div className="modal fade" tabIndex={-1} id="modal-change-password" ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ubah Password</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body fs-6">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>Password Lama</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder='********'
                                    {...formik.getFieldProps('oldPassword')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.oldPassword && formik.errors.oldPassword },
                                        {
                                            'is-valid': formik.touched.oldPassword && !formik.errors.oldPassword,
                                        }
                                    )}
                                    type='password'
                                    name='oldPassword'
                                    autoComplete='off'
                                />
                            </div>
                            {formik.touched.oldPassword && formik.errors.oldPassword && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert' className='text-danger'>{formik.errors.oldPassword}</span>
                                </div>
                            )}
                            <input type="password" style={{ display: "none" }} />
                            <label className='form-label fs-6 fw-bolder text-dark required'>Password Baru</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder='********'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.password && formik.errors.password },
                                        {
                                            'is-valid': formik.touched.password && !formik.errors.password,
                                        }
                                    )}
                                    type='password'
                                    name='password'
                                    autoComplete='off'
                                />
                            </div>
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert' className='text-danger'>{formik.errors.password}</span>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>Konfirmasi Password Baru</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder='********'
                                    {...formik.getFieldProps('confirmPassword')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
                                        {
                                            'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                                        }
                                    )}
                                    type='password'
                                    name='confirmPassword'
                                    autoComplete='off'
                                />
                            </div>
                            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                <div className='fv-plugins-message-container'>
                                    <span role='alert' className='text-danger'>{formik.errors.confirmPassword}</span>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeChangePasswordBtnRef}
                                onClick={handleCloseClick}
                            >
                                Batal
                            </button>
                            <button type="submit" className="btn btn-primary" data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">Ubah</span>
                                <span className="indicator-progress">Harap tunggu...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { ChangePassword }