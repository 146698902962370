/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { login } from '../redux/AuthCRUD'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username harus diisi!'),
  password: Yup.string()
    .min(6, 'Minimum 6 karakter')
    .required('Password harus diisi!'),
})

const initialValues = {
  username: '',
  password: ''
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [logoAuth, setLogoAuth] = useState("");
  const headDescription = document.querySelector("meta[name='description']");
  const headFavicon = document.querySelector("link[rel='shortcut icon']");

  const [loading, setLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        login(values.username, values.password)
          .then(({ data: { token } }) => {
            setLoading(false)
            dispatch(auth.actions.login(token))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Username / Password salah!')
          })
      }, 1000)
    },
  })

  useEffect(() => {
    if (window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011') {
      document.title = "OTP WA"
      headDescription?.setAttribute("content", "OTP Whatsapp");
      headFavicon?.setAttribute("href", toAbsoluteUrl("/media/logos/logo-otpwa-white-2.png"));
      setLogoAuth(toAbsoluteUrl("/media/logos/logo-otpwa-black-2.png"))
    } else {
      document.title = "Wooblazz"
      headDescription?.setAttribute("content", "WooBlazz Corporate");
      headFavicon?.setAttribute("href", toAbsoluteUrl("/media/logos/logo-wooblazz.png"));
      setLogoAuth(toAbsoluteUrl("/media/logos/wooblazz.png"))
    }

    const handleResize = () => {
      if (window.innerWidth < 666) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
      <img alt='Logo' id='logo-auth' src={logoAuth} className='h-125px' />
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.username && formik.errors.username },
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='username'
          name='username'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* end::Link */}
          </div>
        </div>
        <input
          placeholder='********'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Masuk</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {/* end::Action */}
    </form>
  )
}
