export const customNumberFormat = (valueString: number) => {
    const parts = valueString.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
}

export const secondsToTimeFormat = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    if (hours > 0) {
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
}

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed in JavaScript
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

export const formatDateDataTable = (dateString: Date) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // JavaScript's getMonth() returns 0 for January, 1 for February, etc. 
    // So, we add 1 to get the actual month number.
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const generateRandomHash = (length: number) => {
    const array = new Uint8Array(length / 2);
    window.crypto.getRandomValues(array);

    let hash = '';
    for (let i = 0; i < array.length; i++) {
        hash += array[i].toString(16).padStart(2, '0');
    }

    return hash;
}