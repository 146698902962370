import { useState, useEffect } from "react";

export function useTimer(identifier: string, duration: number) {
    const countdownKey = `countDown_${identifier}`;
    const startTimeKey = `startTime_${identifier}`;

    const getRemainingTime = () => {
        const startTime = localStorage.getItem(startTimeKey);
        if (!startTime) return duration;

        const elapsedTime = Math.round((Date.now() - Number(startTime)) / 1000);
        return duration - elapsedTime;
    };

    const initialCountDown = Math.max(getRemainingTime(), 0);

    const [countDown, setCountDown] = useState(initialCountDown);

    useEffect(() => {
        if (countDown <= 0) {
            localStorage.setItem(countdownKey, "0");
            return; // Stop the countdown once it reaches 0
        }

        // If starting the timer, set the start time in localStorage
        if (countDown === duration) {
            localStorage.setItem(startTimeKey, Date.now().toString());
        }

        const timer = setInterval(() => {
            setCountDown(prev => {
                if (prev <= 1) {
                    return 0;
                }
                const newValue = prev - 1;
                localStorage.setItem(countdownKey, newValue.toString());
                return newValue;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [countDown]);

    const resetCountDown = () => {
        setCountDown(duration);
        localStorage.setItem(countdownKey, duration.toString());
        localStorage.removeItem(startTimeKey); // remove the start time on reset
    };

    return { countDown, resetCountDown };
}