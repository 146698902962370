import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const CHECK_SESSION = `https://${window.location.host === 'www.otpwa.com' || window.location.host === 'localhost:3011' ? 'qr.otpwa.com' : 'wa.looyal.id'}/checksession`;

export function checkSession(phone: string) {
    return axios.get(CHECK_SESSION + '?device=' + phone);
}

